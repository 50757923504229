import browser from 'browser-detect'

const baseUrl = (
  window.location.href.indexOf('192.168') !== -1 ||
  window.location.href.indexOf('127.0.0.1') !== -1 ||
  window.location.href.indexOf('localhost') !== -1 ||
  window.location.href.indexOf('dev.') !== -1
) ? 'https://magnetapptest.com' : window.location.origin

const Config = {
  baseUrl: baseUrl,
  captchaUrl: baseUrl + '/Api/captcha',
  payInUrl: baseUrl + '/Api/payInConfirm/',
  passwordChangeEmailPath: `${baseUrl}/Api/sendPasswordChangeEmail/`,
  passwordChangeSmsPath: `${baseUrl}/Api/sendPasswordChangeSms/`,
  passwordChangeRequests: {
    mail: `${baseUrl}/Api/passwordChangeRequest/mail/`,
    sms: `${baseUrl}/Api/passwordChangeRequest/sms/`,
    call: `${baseUrl}/Api/passwordChangeRequest/call/`
  },
  closeGameUrl: baseUrl + '/Api/closeGame/',
  markMessageAsReadUrl: baseUrl + '/Api/messageViewed/',
  environment: browser(),
  version : 2,
  isIos () {
    return this.environment.os.toLowerCase().indexOf('ios') !== -1 ||
            this.environment.os.toLowerCase().indexOf('os x') !== -1
  },
  isAndroid () {
    return this.environment.os.toLowerCase().indexOf('android') !== -1
  },
  isMobile () {
    return this.environment.mobile
  },
  install (Vue) {
    Vue.prototype.$config = this
    Vue.prototype.$_config = this
  },
  makeQueryStringFromObject (obj) {
    const querystring = []
    for (const part in obj) {
      if (obj.hasOwnProperty(part)) {
        querystring.push(encodeURIComponent(part) + '=' + encodeURIComponent(obj[part]))
      }
    }
    return querystring.join('&')
  }
}

export default Config
